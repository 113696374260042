<template>
  <div class="main_lci_wrapper ">
    <div class="main_layout d-flex">
      <div class="cards_and_logo ">
        <div class="render_action">
          <img src="@/assets/new-theme/PLC-Logo.svg" alt="" />

          <img
            v-if="hideXIcon"
            @click="hideAndShowSidebar"
            role="button"
            src="@/assets/new-theme/close.png"
            alt="x-close"
          />
        </div>
        
        <div v-if="hideShowSidebar == false">
          <h4 class="mb-0 pb-0" >{{ localization("Why is this important?") }}</h4>
          <div class="cards_wrapper" v-for="(item, i) in cardItems" :key="i">
            <LciCard :item="item"></LciCard>
          </div>
        </div>
        <div
          class="mt-5 absolute_question_mark"
          v-if="!hideXIcon"
          :class="{ make_absolute: hideShowSidebar == true }"
        >
          <div
            @click="hideAndShowSidebar"
            role="button"
            class="mark_tooltip"
            v-tooltip.top-center="`${localization('Useful tips and support')}`"
          >
            <img src="@/assets/new-theme/icons/help-circle@2x.png" alt="" />
          </div>
        </div>
      </div>
      <div class="tabs_wrapper pt-3 px-2">
        <div class="step_number_and_questions_number">
          <img
            role="button"
            src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
            alt=""
          />
          <div class="d-flex">
            <span class="mr-2" style="font-size: 24px; font-weight: 500; font-family: Poppins;">
              {{ localization("Step 3") }} - {{ localization("Team details") }}
            </span>
          </div>

        </div>
        <div class="tabs_steps mt-4">
          <span
            v-for="(tab, index) in 4"
            :key="tab"
            class="d-block step"
            :class="{ active: isActive(index) }"
          >
          </span>
        </div>
        
        <div
          class="global_theme_ui_collapse main_profile_personalize_and_collapse"
        >
          
          <div class="pt-4">
            <el-form
              :model="teamForm"
              :rules="rules"
              ref="addTeam"
              :class="{ 'text-right build_with_rtl': isRtl() }"
            >
              <div class="container-fluid">
                  <div class="row">
                      <div class="col-md-12">
                          <h4 class="m-0">{{ localization("Enter team details") }}</h4>
                          <p class="text-muted m-0">{{ localization("Enter the team name and add members to it.") }}</p>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-12">
                          <div class="row pt-2" :class="{ 'd-flex flex-row-reverse': isRtl() }">
                              <div class="col-md-6 my-2">
                                  <el-form-item :label="`${localization('Name your team')}*`" prop="name">
                                      <el-input
                                          :placeholder="`${localization('Give your team a name')}`"
                                          v-model="teamForm.name"
                                      ></el-input>
                                  </el-form-item>
                              </div>
                          </div>
                          <div class="row pt-4">
                              <div class="col-md-6 pb-2">
                                  <h4 class="m-0">{{ localization("Invite members to your team") }}*</h4>
                                  <p class="text-muted m-0 mb-3">{{ localization("Invite members to your team by any of the following methods.") }}</p>
                                  <div class="border rounded-lg d-flex p-3" sty>
                                      <div class="mr-3">
                                          <img src="@/assets/new-theme/i.png" alt="" >
                                      </div>
                                      <div>
                                          <p class="m-0" >{{ localization("Team should have at least 3 members") }}</p>
                                          <p class="text-muted m-0">{{ localization("Invite up to 5 members to your team") }}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-md-6 my-2">
                                  <el-form-item :label="`${localization('Member from your contacts')}`">
                                      <el-select
                                          ref="users"
                                          :placeholder="`${localization('Select and add a team members')}`"
                                          :no-data-text="`${localization('Write Name')}`"
                                          style="width: 100%"
                                          v-model="teamForm.selected_team_members"
                                          filterable
                                          multiple
                                          remote
                                          reserve-keyword
                                          :remote-method="search"
                                          :loading="loadingValue"
                                      >
                                          <el-option
                                          v-for="user in contactList"
                                          :key="user.id"
                                          :label="user.first_name + ' ' + user.last_name"
                                          :value="user.id"
                                          :disabled="teamForm.selected_team_members.length == 5"
                                          >
                                          <span style="color: #8492a6; font-size: 13px">
                                              <i class="el-icon-user-solid"></i>
                                              {{ user.first_name + " " + user.last_name }}
                                          </span>
                                          </el-option>
                                      </el-select>
                                  </el-form-item>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-md-6">
                                  <el-form-item label="Institution Members" prop>
                                      <el-select
                                          v-model="teamForm.selected_institutions_users"
                                          :placeholder="`${localization('Select and add Institution members')}`"
                                          style="width: 100%"
                                          multiple
                                          class
                                          filterable
                                      >
                                          <el-option
                                              v-for="item in institutionUsersList"
                                              :key="item.id"
                                              :label="item.first_name + ' ' + item.last_name"
                                              :value="item.id"
                                          ></el-option>
                                      </el-select>
                                  </el-form-item>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-12 mb-2">
                          <div class="row pt-2" :class="{ 'd-flex flex-row-reverse': isRtl() }">
                              <div class="col-md-6 my-2">
                                  <el-form-item :label="`${localization('Invite members by email')}`" style="width: 100%" prop="emails">
                                      <el-select
                                          v-model="teamForm.emails"
                                          :placeholder="`${localization('Enter the member email')}`"
                                          style="width: 100%"
                                          multiple
                                          class
                                          filterable
                                          :no-data-text="`${localization('Type Email')}`"
                                          allow-create
                                          default-first-option
                                      ></el-select>
                                  </el-form-item>
                              </div>
                          </div>
                      </div>
                      <!-- <div class="col-md-12 my-2">
                          <div class="dialog_footer" :class="{ 'justify-content-start': isRtl() }">
                              <el-button
                                  class="text-center text-capitalize save_button"
                                  @click="save_team"
                              >
                                  {{ localization("Add Team") }}
                              </el-button>
                          </div>
                      </div> -->
                  </div>
              </div>
            </el-form>
            <div>
              <div v-if="line_graph_members.length >= 1">
                <div class="the_main_wrapper_for_charts" v-if="!loadingValue">
                    <div class="is_chart_wrapper">
                    <chart-card
                        v-if="scores.length > 0"
                        :chart-data="teamChart"
                        :chart-options="barChart.options"
                        :chart-responsive-options="barChart.responsiveOptions"
                    >
                        <template slot="header">
                        <div class="d-flex" :class="{ 'flex-row-reverse': isRtl() }">
                            <i
                            class="el-icon el-icon-data-analysis"
                            style="font-size: 30px; margin-right: 7px"
                            ></i>
                            <h4 class="card-title">{{ localization("Team Line Graph") }}</h4>
                        </div>
                        </template>
                    </chart-card>
                    <div class="lines_in_chart avoid">
                        <span class="is_text_chart">{{ localization("Avoid") }}</span>
                    </div>
                    <div class="lines_in_chart needed">
                        <span class="is_text_chart">{{ localization("As Needed") }}</span>
                    </div>
                    <div class="lines_in_chart first">
                        <span class="is_text_chart">{{ localization("Use First") }}</span>
                    </div>
                    </div>
                    <div
                    class="for_line_graph_members_container"
                    :class="{ for_line_graph_members_with_rtl: isRtl() }"
                    >
                    <div
                        v-for="(member, index) in line_graph_members"
                        :key="member.id"
                        class="every_bullet_wrapper"
                        :class="`member_${index + 1}`"
                    >
                        <span class="bullet"></span>
                        <span class="text-capitalize">{{ member.first_name }} {{ member.last_name }}</span>
                    </div>
                    </div>
                    <div class="text-center team_description_text">
                    <p>{{ line_graph_members_description }}</p>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-3 d-flex justify-content-center mb-5">
            <button
              @click="prevTab"
              class="prev_next"
            >
              <img
                src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
                alt="edit icon"
              />
            </button>
            <button
              @click="nextTab"
              class="lci_btn save mx-4"
            >
              {{ localization("Save and Continue") }}
            </button>

            <button
              @click="nextTab"
              class="prev_next"
            >
              <img
                class="flip-horizontal"
                src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
                alt="edit icon"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TeamForm from "./TeamForm.vue";
import TeamAnswers from "./TeamAnswers.vue";
import { ChartCard, StatsCard } from "src/components/index";
import Swal from "sweetalert2";
import { param } from "jquery";
export default {
  name: "PwpQuestions",
  data() {
    return {
      hideShowSidebar: false,
      getAllStatements: {},
      hideXIcon: false,
      cardItems: [
        {
          src: require("@/assets/new-theme/join.png"),
          title: this.localization("Invite team members"),
          description:
            this.localization("Build a team by either inviting those who may have already taken the LCI or inviting team members to do so.")
        },
        {
          src: require("@/assets/new-theme/tv.png"),
          title: this.localization("See team's LCI scores"),
          description:
            this.localization("Once they accept the invitation and complete the LCI, you will see the team's LCI scores both as a table and as a graph.")
        },
        {
          src: require("@/assets/new-theme/elements.png"),
          title: this.localization("Similarities and differences"),
          description:
            this.localization("This will help all of you see the similarities and differences in your team uses their Learning Patterns.")
        }
      ],
      tabs: [],
      active: 0,
      activeTabs: [0],
      currentTab: 0,
      team_id: null,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      selected: [],

      teamForm: {
        name: "",
        selected_team_members: [],
        emails: [],
        selected_institutions_users: []
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      barChart: {
        options: {
          seriesBarDistance: 20,
          showPoint: true,
          labelDirection: "explode",
          chartPadding: 0,
          labelOffset: 20,
          low: 7,
          high: 40,
          showLine: true,
          labelInterpolationFnc: function(value) {
            return `${value.split(" ")[0]}...`;
          },
          height: "350px",
          width: "95%",
          axisX: {
            offset: 30,
            position: "end",
            showLabel: true,
            showGrid: true,
            labelOffset: {
              x: 0,
              y: 5
            }
          },
          axisY: {
            offset: 50,
            position: "start",
            showLabel: true,
            showGrid: true,
            scaleMinSpace: 20,
            onlyInteger: true,
            labelOffset: {
              x: 0,
              y: 0
            }
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      }
    };
  },
  components:{
    TeamForm,
    TeamAnswers,
    ChartCard, 
    StatsCard
  },
  computed: {
    ...mapGetters({
      userLoad: ["teams/userLoad"],
      questionTwo: ["teams/questionTwo"],
    }),
    careerMatchStatementsQuestions() {
      return this.$store.getters["user/getCareerMatchStatementsQuestions"];
    },
    mainUserId() {
      return this.auth().id;
    },
    questionTitle() {
      if(this.questionTwo){
        return Object.keys(this.questionTwo)[0];
      }
    },

    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      contactList: ["contacts/contactList"],
      line_graph_members: ["user/line_graph_members"],
      line_graph_members_description: ["user/line_graph_members_description"],
      team_pages_content: ["user/get_team_pages_content"],
      team_pages_titles_content: ["user/get_team_pages_titles_content"],
      institutionUsersList: ["groups/getInstUsers"]
    }),
    scores() {
      let scores = [];
      this.line_graph_members.forEach(element => {
        scores.push([
          element.sequence,
          element.precision,
          element.technical_reasoning,
          element.confluence
        ]);
      });
      return scores;
    },
    teamChart() {
      let data = {};

      data.labels = [
        "Sequence",
        "Precision",
        "Technical Reasoning",
        "Confluence"
      ];
      data.series = this.scores;
      return data;
    }
  },
  methods: {
    hideAndShowSidebar() {
      this.hideShowSidebar = !this.hideShowSidebar;
      this.hideXIcon = !this.hideXIcon;
    },
    isActive(index) {
      return [0, 1, 2].includes(index);
    },
    updatePanel(index) {
    },
    switchTab(index) {
    },
    nextTab() {
      this.save_team()
    },
    teamsAnswers(id) {
      this.team_id = id;
    },
    prevTab() {
      this.$router.push({name:'step-1'})
    },
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(res => {
        if (
          res.user.my_career_statements &&
          res.user.my_career_statements != null &&
          res.user.my_career_statements != false
        ) {
          this.selected = JSON.parse(res.user.my_career_statements);
        } else {
          this.selected = [];
        }
      });
    },
    updateSelectedAttributes() {
      this.$store
        .dispatch("attributes/updateCareerMatchStatements", {
          id: this.mainUserId,
          query: { my_career_statements: this.selected }
        })
    },
    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    },
    getKey(any_key) {
      return any_key;
    },
    getContactsList(query) {
      this.$store.dispatch("contacts/GetContactsList", { query: query });
    },
    search(query) {
      this.usersRoles(query);
    },
    usersRoles(search = "", limit = 10) {
      this.$store
        .dispatch("contacts/GetContactsList", {
          search_text: search
        })
        .then(() => {
          this.search_group = this.$store.getters["contacts/contactList"];
        });
    },
    teamLineGraphHandler() {
      this.$store.dispatch("user/get_team_line_graph", {
        users: [...this.teamForm.selected_team_members, this.auth().id]
      });
    },
    teamLineGraphHandlerForEmptyData() {
      this.$store.dispatch("user/get_team_line_graph", {
        users: this.teamForm.selected_team_members
      });
    },
    save_team() {
      this.$refs["addTeam"].validate(valid => {
        if (valid) {
          let selectedMem = [
            ...this.teamForm.selected_team_members,
            ...this.teamForm.selected_institutions_users
          ];
          this.$store
            .dispatch("user/add_team", {
              name: this.teamForm.name,
              emails: this.teamForm.emails,
              users: selectedMem
            })
            .then(response => {
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              }).then(data => {
                // this.$router.push({name:'step-4', params:{teamId: response.team.id}})
                this.$router.push(`/new-theme/build-team/step-4/${response.team.id}`);
                if (data.isConfirmed) {
                  this.teamForm.name = "";
                  this.teamForm.emails = [];
                  this.teamForm.selected_team_members = [];
                  this.teamForm.selected_institutions_users = [];
                  this.teamLineGraphHandlerForEmptyData();
                  this.$emit("teamsAnswers", response.team.id)
                }
              });
            });
        } else {
        }
      });
    }
  },
  mounted() {
    this.getMainUserData();
    this.$store.dispatch("user/get_all_teams_content");
    this.$store.dispatch("groups/institutionUsersGroup");
  }
};
</script>

<style lang="scss" scoped>
.main_lci_wrapper {
  width: 100%;

  .render_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main_layout {
    gap: 40px;
  }
  .cards_and_logo {
    background: #fcfcfd;
    max-width: 25%;
    min-width: 25%;
    padding: 20px;
    position: relative;
    .absolute_question_mark {
      position: fixed;
      left: 10px;
      top: 82%;
      &.make_absolute {
        position: absolute;
        bottom: 0;
      }
    }
    .cards_wrapper {
      margin-top: 40px;
    }
  }
  // Tabs
  .tabs_wrapper {
    flex-grow: 1;
    // background: #eee;
    .step_number_and_questions_number {
      display: flex;
      align-items: center;
      gap: 10px;
      .st_number_value{
        color: #101828;
        font-size: 22px;
        font-weight: 500;
      }
      .st_questions_number {
        color: #667085;
        font-size: 14px;
      }
      .st_number_value{
        p{
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
    .tabs_steps {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      .step {
        height: 4px;
        border-radius: 8px;
        background-color: #e9d7fe;
        flex-grow: 1;
        // width: 180px;
        &.active {
          background-color: #7f56d9;
        }
      }
    }
  }
}
// Buttons Next And Previous
.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}
.prev_next {
  background-color: #f2f4f7;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  color: #667085;
  width: 70px;
}
.mark_tooltip {
  border: 1px solid #d0d5dd;
  width: fit-content;
  padding: 12px;
  border-radius: 50%;
}

.main_profile_personalize_and_collapse{
  position: relative;
}

.main_profile_personalize_and_collapse::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-size: contain;
  opacity: 0.5; 
  z-index: -1;
  pointer-events: none;
}

.main_profile_personalize_and_collapse {
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), 
              url('../../../assets/new-theme/lci/background.png') no-repeat right center;
  background-size: contain;
}
</style>
<style lang="scss">
.main_profile_personalize_and_collapse {
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__content {
    box-shadow: none !important;
    border: none !important;

    // @media print {
    //   padding-bottom: 0;
    // }
  }
  .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
    font-size: 16px;
    line-height: inherit;
    color: #344054 !important;
  }
  .el-checkbox__inner {
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    height: 20px;
    width: 20px;
  }
  .el-checkbox {
    align-items: flex-start;
    display: flex;
  }
  .el-checkbox__label,
  .el-checkbox is-checked {
    color: #344054 !important;
    font-weight: 400;
    font-size: 16px;

    // max-width: 98%;
    word-break: break-word !important;
    white-space: pre-wrap;
    // word-wrap: break-word;
    white-space: normal;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #6941c6;
    border-color: #6941c6;
  }
  .el-checkbox__inner::after {
    left: 7px;
    top: 4px;
    border-width: 2px;
  }
}
</style>
<style scoped>
::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-right: 4px;
  margin-bottom: 0px;
}

::v-deep .el-form-item__label{
    margin: 0px;
    color: #344054 !important;
}

::v-deep .el-input__inner{
  border-radius: 8px;
}

::v-deep .el-input__inner::placeholder {
  color: #667085;
  opacity: 1; /* Firefox */
}
</style>